import Spin from '@/components/basic/spin';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {debounce, goBack, goTo} from '@/utils';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, {useCallback} from 'react';
import {View, Image} from 'react-native';
import {
  DiceConfigInfo,
  DiceCurrentResultItem,
  DiceHomeItem,
  DiceResultItem,
  OrderResContentItem,
  dicePay,
  getDiceBetting,
  getDiceCurrentResult,
  getDiceHomeList,
  getDiceOrderList,
  getLatestOneStatisticsResult,
} from './dice-service';
import {getShareAward} from '@/services/global.service';
import {Times} from '@/components/business/games/times-list';
import DiceTimeResult from './dice-time-result';
import BetsSelect from './bets-select-tab/bets-select';
import DiceTab from './dice-tab/dice-tab';
import {Bets} from '@/components/business/games';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import RuleModal from '@/components/business/games/rule';
import ProgressBar from './component/progress';
import globalStore from '@/services/global.state';
import BetsEle from './component/bets-type-ele';
import {Overlay} from '@rneui/themed';
import BetsTip from './component/bets-tip';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import DiceRule from './dice-rule';
import SaleRule from './dice-sale-rule';
import GoldWin from '@/components/business/games/gold-win';
import {checkIsShown, saveIssueNum} from '@/components/utils/gameWin';
import {ScrollView} from 'react-native-gesture-handler';
import Video from '@/components/basic/video';
// import Result from './dice-tab/result/result';
// import Winner from './dice-tab/winner/winner';
// import Analyze from './dice-tab/analyze/analyze';
// import OrderBetsList from './dice-tab/order/order';
import DiceTabView from './dice-tab/dice-tab-veiw';
import PaidShareModal from '@/components/business/paid-share-modal';
import {getUUID, transfor} from '@/utils';
import {SafeAny} from '@/types';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import {useTranslation} from 'react-i18next';
interface betsInfoType {
  type: string;
  code: string;
  digit: number;
  digitType: string;
}

const DiceGame = () => {
  const {i18n} = useTranslation();
  const user = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(user).userId;
  const params = useRoute().params as {
    cycle: string;
    configId: string;
  };
  const {cycle = 1, configId = 0} = {
    cycle: parseInt(params?.cycle || '0', 10),
    configId: parseInt(params?.configId || '0', 10),
  };
  const drawerRef = React.useRef<DrawerRef>(null);
  const [timeList, setTimeList] = React.useState<DiceHomeItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [currentTimeConf, setCurrentTimeConf] = React.useState<DiceHomeItem>();
  const [currentConf, setCurrentConf] = React.useState<DiceCurrentResultItem>();
  const [latestOne, setLatestOne] = React.useState<DiceResultItem>();
  const [prevOne, setPrevOne] = React.useState<OrderResContentItem>();
  const [currentTime, setCurrentTime] = React.useState(-1);
  const [disabled, setDisabled] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [saleRuleVisible, setSaleRuleVisible] = React.useState(false);
  const [rollPaused, setRollPaused] = React.useState(true);
  const [diceConf, setDiceConf] = React.useState<DiceConfigInfo>();
  const [winPaused, setWinPaused] = React.useState(true);
  const [winAmount, setWinAmount] = React.useState(0);
  const [showWin, setShowWin] = React.useState(false);
  const lockToast = React.useRef(false);
  const [betsInfo, setBetsInfo] = React.useState<betsInfoType>();
  const [tipType, setTipType] = React.useState<
    'single' | 'double' | 'triple' | 'any'
  >('single');
  const [showTip, setShowTip] = React.useState(false);
  const [betsOrderList, setBetsOrderList] = React.useState<{
    [key: string]: betsInfoType[];
  }>({});
  const [orderNum, setOrderNum] = React.useState(0);
  const [refreshCode, setRefreshCode] = React.useState(0);
  const currentCode = React.useRef(0);
  const totalOrder = React.useRef(0);
  const isOrderActive = React.useRef(false);

  const [timeVersion, setTimeVersion] = React.useState(0);
  const {getState} = useNavigation<NavigationProp<any>>();
  const {index: stateIndex, routes} = getState();
  const currentRouteName = routes[stateIndex].name;
  // share award
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [popVisible, setPopVisible] = React.useState<boolean>(false);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});

  React.useEffect(() => {
    getTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAmount = useCallback(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next({gameType: 'Dice'});
    }
  }, []);
  useFocusEffect(handleUpdateAmount);

  const getTimes = async () => {
    try {
      const res = await getDiceHomeList();
      if (res) {
        setTimeList(res);
        const find = res.find(item => item.id === configId);
        if (find) {
          setCurrentTimeConf(find);
        } else {
          setCurrentTimeConf(res[0]);
        }
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (currentTimeConf?.id) {
      getDiceConf(currentTimeConf.id);
    }
  }, [currentTimeConf]);

  const getDiceConf = async (conf: number) => {
    try {
      setLoading(true);
      const res = await getDiceBetting(conf);
      if (res) {
        setDisabled(false);
        lockToast.current = false;
        setDiceConf(res);
        const currentResult = await getDiceCurrentResult(conf);
        if (currentResult) {
          // startRemaining(currentResult);
          setTimeVersion(v => v + 1);
        }
        setCurrentConf(currentResult);
      }
      const latest = await getLatestOneStatisticsResult(conf);
      if (latest) {
        // if (!isOrderActive.current) {
        //   if (!totalOrder.current) {
        //     totalOrder.current = latest.totalSize;
        //     setOrderNum(0);
        //   } else {
        //     setOrderNum(latest.totalSize - totalOrder.current);
        //   }
        // } else {
        //   totalOrder.current = latest.totalSize;
        //   if (!orderNum) {
        //     setOrderNum(0);
        //   }
        // }
        setLatestOne(latest.content[0]);
        setRollPaused(false);
      }
      if (globalStore.token) {
        const prev = await getDiceOrderList({
          orderStatus: '3',
          pageNo: 1,
          pageSize: 1,
        });
        if (prev) {
          setPrevOne(prev.content?.[0] || {});
        }
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (latestOne && prevOne && latestOne.issNo === prevOne.issNo) {
      if (prevOne.totalAwardAmount > 0) {
        // 中奖
        checkIsShown('dice', prevOne.issNo).then((res: boolean) => {
          if (!res) {
            setWinAmount(prevOne.totalAwardAmount);
            setShowWin(true);
            setWinPaused(false);
            globalStore.updateAmount.next({gameType: 'Dice'});
            saveIssueNum('dice', prevOne.issNo);
            setThreeOrderData({
              userId,
              data: transfor({...prevOne, userId}, 'dice', {from: 'order'}),
            });
          }
        });
        // getShareReward('dice').then(res => {
        //   setShareParams(
        //     {
        //       gameName: 'Dice',
        //       gameInfo: {
        //         shareAward: res || 0,
        //         shareGameDto: {
        //           issNo: lastOrderData.issueNo,
        //           gameCode: 'color'
        //         }
        //       }
        //     }
        //   )
        //   setAwardAmount(lastOrderData.awardAmount);
        //   gameResultRef.current?.show();
        // })
      }
    }
  }, [latestOne, prevOne, userId]);

  const onBetsSubmit = (total: number) => {
    try {
      if (!globalStore.token) {
        drawerRef.current?.close();
        goTo('Login');
        return;
      }
      if (disabled || loading) {
        return;
      }
      drawerRef.current?.close();
      setLoading(true);
      dicePay(currentConf!.issueNo, betsInfo!.code, total).then(() => {
        if (!globalStore.imIcon) {
          globalStore.globalSucessTotal(i18n.t('paidSuccess.label.subTitle'));
        }
        const user = localStorage.getItem('user') || '{}';
        const userId = JSON.parse(user).userId;
        const shareUserId = localStorage.getItem('shareUserId');
        const [imStr, imGameCode] = (
          localStorage.getItem('PlayNow') || '-'
        ).split('-');
        if (shareUserId && imStr === 'im' && imGameCode === 'Dice') {
          postReport({
            reportType: TReportType.BETS,
            gameName: `Dice-${currentConf!.cycle} Minutes`,
            gameType: 'dice',
            userId,
            issueNo: currentConf!.issueNo,
            sourceType: TSourceType.IM,
            shareUserId,
          });
          localStorage.removeItem('PlayNow');
        }

        getShareAward('dice').then(award => {
          setShareAward(Math.floor(award));
          setPopVisible(true);
          const rawData = {
            issueNo: currentConf!.issueNo,
            selectTypeSet: [betsInfo!.code],
            baseAmount: total,
            totalAmount: total,
          };
          getDiceOrderList({
            orderStatus: '3',
            pageNo: 1,
            pageSize: 1,
          }).then(r => {
            setThreeOrderData({
              userId,
              data: transfor({...rawData, userId}, 'dice', {
                rest: {
                  gameIconUrl: r?.content[0]?.gameIconUrl,
                  gameName: r?.content[0]?.gameName,
                  issNo: r?.content[0]?.issNo,
                  openTime: r?.content[0]?.openTime,
                  totalAmount: total,
                },
              }),
            });
          });
        });
        if (betsInfo) {
          updateDicePayOrder(currentConf!.issueNo, betsInfo);
        }
        totalOrder.current += 1;
        setOrderNum(totalOrder.current);
        globalStore.updateAmount.next({gameType: 'Dice'});
      });
    } finally {
      setLoading(false);
    }
  };

  const onBetsSubmitCheck = debounce((totalPrice: number) => {
    onBetsSubmit(totalPrice);
  }, 200);

  const updateDicePayOrder = (issNo: string, info: betsInfoType) => {
    const current = betsOrderList[issNo] || [];
    current.push(info);
    const target = Object.assign({}, betsOrderList, {[issNo]: [...current]});
    setBetsOrderList(target);
  };
  // const {height: screenHeight} = useResponsiveDimensions();
  const [index, setIndex] = React.useState(0);

  return (
    <View style={[theme.background.lightGrey, theme.flex.flex1]}>
      <DetailNavTitle
        title={`Dice-${currentTimeConf?.cycle || cycle} Minutes`}
        hideServer={true}
        onBack={() => {
          goBack();
          localStorage.removeItem('PlayNow');
        }}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView stickyHeaderIndices={[4]}>
          {timeList.length > 1 ? (
            <Times
              minutes={timeList}
              configId={currentTimeConf?.id}
              onTimePress={id => {
                const find = timeList.find(item => item.id === id);
                if (find) {
                  setCurrentTimeConf(find);
                }
              }}
            />
          ) : (
            <View />
          )}
          <DiceTimeResult
            showRule={() => setRuleVisible(true)}
            cycle={currentConf?.cycle}
            issueNumber={currentConf?.issueNo}
            prevIssueNum={latestOne?.issNo}
            countdownTime={currentConf?.countdownTime}
            bigOrSmall={latestOne?.bigOrSmall}
            oddOrEven={latestOne?.oddOrEven}
            result={latestOne?.result}
            totalCount={latestOne?.totalCount}
            onTimeEnd={() => {
              if (currentTimeConf?.id) {
                getDiceConf(currentTimeConf.id);
                currentCode.current += 1;
                setRefreshCode(currentCode.current);
              }
            }}
            timeVersion={timeVersion}
            onDisabled={() => {
              setDisabled(true);
              drawerRef.current?.close();
            }}
            isSameRoute={currentRouteName === 'Dice'}
            stopBetSec={currentConf?.minRemainingSecond || 10}
            onTimeChange={setCurrentTime}
          />
          <ProgressBar
            total={currentConf ? currentConf?.cycle * 60 : 0}
            value={currentTime}
          />
          <BetsSelect
            disabled={disabled}
            onTip={type => {
              setTipType(type);
              setTimeout(() => {
                setShowTip(true);
              }, 0);
            }}
            betsList={currentConf ? betsOrderList[currentConf?.issueNo] : []}
            diceConf={diceConf}
            onSelect={(code, v) => {
              if (disabled) {
                return null;
              }
              const typeArr = code.split('_');
              const type = typeArr[0];
              setBetsInfo({
                type,
                code,
                digit: typeof v === 'number' ? v : 0,
                digitType: typeof v === 'string' ? v : '',
              });
              drawerRef.current?.open();
            }}
          />
          <DiceTab
            orderNum={orderNum}
            index={index}
            setIndex={setIndex}
            onOrderActive={active => {
              isOrderActive.current = active;
              if (active && totalOrder.current) {
                totalOrder.current = 0;
                setOrderNum(0);
              }
            }}
          />
          <DiceTabView
            refreshCode={refreshCode}
            configId={currentTimeConf?.id}
            index={index}
          />
        </ScrollView>
      </Spin>
      <PaidShareModal
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        shareAward={shareAward}
        sharInfo={{
          gameCode: 'dice',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <Drawer
        beforeClose={() => {}}
        ref={drawerRef}
        mode="bottom"
        contentBackgroundColor="#0000">
        <Bets
          BallEle={
            <BetsEle
              type={betsInfo?.type}
              digit={betsInfo?.digit}
              digitType={betsInfo?.digitType}
            />
          }
          onBitSubmit={onBetsSubmitCheck}
          onSeeRule={() => setSaleRuleVisible(true)}
          onClose={() => drawerRef.current?.close()}
        />
      </Drawer>
      <Overlay
        isVisible={showTip}
        overlayStyle={{borderRadius: theme.borderRadiusSize.m * 2}}>
        <BetsTip type={tipType} />
        <View
          style={[
            theme.flex.centerByCol,
            theme.position.abs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              bottom: -theme.iconSize.xxl - 32,
              left: 0,
              right: 0,
            },
          ]}>
          <NativeTouchableOpacity
            onPress={() => {
              setShowTip(false);
            }}>
            <Image
              style={[theme.icon.xxl]}
              source={require('@assets/icons/home/button-close.png')}
            />
          </NativeTouchableOpacity>
        </View>
      </Overlay>
      <RuleModal
        title="Rule"
        visible={ruleVisible}
        onClose={() => setRuleVisible(false)}>
        <DiceRule />
      </RuleModal>
      <RuleModal
        title="Rule"
        visible={saleRuleVisible}
        onClose={() => setSaleRuleVisible(false)}>
        <SaleRule />
      </RuleModal>
      <GoldWin
        amount={winAmount}
        visible={showWin}
        shareAward={shareAward}
        onClose={() => {
          setShowWin(false);
        }}
        sharInfo={{
          gameCode: 'dice',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <Video
        onEnd={() => setRollPaused(true)}
        audioOnly
        paused={rollPaused}
        source={require('@components/assets/music/rolling.mp3')}
        src="/assets/mp3/rolling.mp3"
      />
      <Video
        onEnd={() => {
          setWinPaused(true);
        }}
        audioOnly
        source={require('@components/assets/music/win.mp3')}
        src={require('@components/assets/music/win.mp3')}
        paused={winPaused}
      />
    </View>
  );
};

export default DiceGame;
